<mat-list-item [class.active]="selected">
  <a
    mat-button
    color="primary"
    id="document-information"
    class="section-like"
    (click)="collapseSidebar()"
    [routerLink]="documentOverviewRoute"
    aria-label="View document information"
  >
    {{ currentView?.isHistoricalDocument() ? 'Version information' : 'Document information' }}
  </a>

  <cars-alt-accessibility
    class="document-information"
    [altProperties]="{initialKey: 'q', key: 'r', condition: true}"
    (onSelect)="focusOverview()"
  ></cars-alt-accessibility>

  <button
    class="document-menu"
    mat-icon-button
    [matMenuTriggerFor]="documentMenu"
    matTooltip="Open document menu"
    matTooltipPosition="after"
    [matTooltipShowDelay]="tooltipDelay"
    aria-label="Open document menu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
</mat-list-item>

<mat-menu #documentMenu="matMenu">
  <button
    mat-menu-item
    (click)="showVersionCreator(true)"
    *ngIf="CarsAction.CREATE_TEMPLATE | carsEnabled : document?.id | async"
  >
    <mat-icon>class</mat-icon>
    Create template
  </button>

  <button
    mat-menu-item
    (click)="showVersionCreator()"
    *ngIf="CarsAction.AUTHOR_DOCUMENT | carsEnabled : document?.id | async"
  >
    <mat-icon>content_copy</mat-icon>
    Create version
  </button>

  <a mat-menu-item [routerLink]="documentVersionsRoute" aria-label="See versions">
    <mat-icon>history</mat-icon>
    Versions
  </a>

  <button
    mat-menu-item
    (click)="showSectionClone()"
    *ngIf="CarsAction.AUTHOR_DOCUMENT | carsEnabled : document?.id | async"
  >
    <mat-icon>copy_all</mat-icon>
    Clone section
  </button>

  <a mat-menu-item [routerLink]="changeSummaryRoute" aria-label="See clause change summary">
    <mat-icon>compare</mat-icon>
    Clause change summary
  </a>

  <button
    mat-menu-item
    [matMenuTriggerFor]="pdfMenu"
    disableRipple="true"
    *ngIf="CarsAction.EXPORT_DOCUMENT | carsEnabled : document?.id | async"
  >
    <mat-icon>picture_as_pdf</mat-icon>
    Export to PDF
  </button>

  <button
    mat-menu-item
    (click)="handleExportFile(ExportType.HTML_NOT_FOR_PUBLICATION)"
    *ngIf="CarsAction.EXPORT_DOCUMENT | carsEnabled : document?.id | async"
  >
    <span id="htmlIcon" class="material-icons">html</span>
    Export to HTML
  </button>

  <button
    mat-menu-item
    (click)="openMchwExportModal(ExportType.WEB_PDF)"
    *ngIf="CarsAction.EXPORT_MCHW_DOCUMENT | carsEnabled : document?.id | async"
  >
    <mat-icon>picture_as_pdf</mat-icon>
    Export to PDF
  </button>

  <button
    mat-menu-item
    (click)="openMchwExportModal(ExportType.HTML_NOT_FOR_PUBLICATION)"
    *ngIf="CarsAction.EXPORT_MCHW_DOCUMENT | carsEnabled : document?.id | async"
  >
    <span id="htmlIcon" class="material-icons">html</span>
    Export to HTML
  </button>

  <button
    mat-menu-item
    [matMenuTriggerFor]="pdfPreviewMenu"
    disableRipple="true"
    *ngIf="CarsAction.CAN_VIEW_PRINT_PREVIEW | carsEnabled : document?.id | async"
  >
    <mat-icon>find_in_page</mat-icon>
    Document Preview
  </button>

  <a
    mat-menu-item
    [routerLink]="wsrSubjectTopicRoute"
    aria-label="See WSR subject topic"
    *ngIf="CarsAction.IS_MCHW_DOCUMENT | carsEnabled : document?.id | async"
  >
    <mat-icon>account_tree</mat-icon>
    WSR subject and topic mapping
  </a>
</mat-menu>

<mat-menu #pdfPreviewMenu="matMenu">
  <button mat-menu-item (click)="export(ExportType.WEB_PDF, true)" carsAnalytics="preview-web-pdf">
    <mat-icon>desktop_windows</mat-icon>
    Online View
  </button>
  <button mat-menu-item (click)="export(ExportType.WLT_PDF, true)" carsAnalytics="preview-wlt-pdf">
    <mat-icon>import_contacts</mat-icon>
    Hardcopy View
  </button>
</mat-menu>

<mat-menu #pdfMenu="matMenu">
  <button mat-menu-item (click)="handleExportFile(ExportType.WEB_PDF)" carsAnalytics="export-to-web-pdf">
    <mat-icon>desktop_windows</mat-icon>
    Web
  </button>
  <button mat-menu-item (click)="handleExportFile(ExportType.WLT_PDF)" carsAnalytics="export-to-wlt-pdf">
    <mat-icon>import_contacts</mat-icon>
    WLT
  </button>
</mat-menu>
