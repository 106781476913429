/**
 * An enumeration of all available actions. Should be kept in sync with
 * the matching backend enum.
 */
export enum CarsAction {
  AUTHOR_DOCUMENT = 'AUTHOR_DOCUMENT',
  AUTHOR_DOCUMENT_UNLESS_NAA = 'AUTHOR_DOCUMENT_UNLESS_NAA',
  ASSIGN_REVIEWERS = 'ASSIGN_REVIEWERS',
  EDIT_PAD = 'EDIT_PAD',
  IS_COMMENTABLE = 'IS_COMMENTABLE',
  CAN_SELECT_CLAUSE = 'CAN_SELECT_CLAUSE',
  LOCK_CLAUSE = 'LOCK_CLAUSE',
  SHOW_CARS_CHAT = 'SHOW_CARS_CHAT',
  SHOW_PAD_TOOLBAR = 'SHOW_PAD_TOOLBAR',
  SHOW_RIGHT_SIDEBAR = 'SHOW_RIGHT_SIDEBAR',
  PAD_MAX_HEIGHT = 'PAD_MAX_HEIGHT',
  SHOW_SECTION_PAD_HEADER = 'SHOW_SECTION_PAD_HEADER',
  SHOW_REVIEWER_INFO = 'SHOW_REVIEWER_INFO',
  SHOW_VERSION_HISTORY = 'SHOW_VERSION_HISTORY',
  SHOW_REFERENCES = 'SHOW_REFERENCES',
  SHOW_CLAUSE_REFERENCES = 'SHOW_CLAUSE_REFERENCES',
  SHOW_CLAUSE_LINKS_SIDEBAR = 'SHOW_CLAUSE_LINKS_SIDEBAR',
  SHOW_SECTION_REFERENCES_SIDEBAR = 'SHOW_SECTION_REFERENCES_SIDEBAR',
  SHOW_UNIT_SELECTION_SIDEBAR = 'SHOW_UNIT_SELECTION_SIDEBAR',
  USER_DISCUSSIONS_ONLY = 'USER_DISCUSSIONS_ONLY',
  VERSION_AVAILABLE_FOR_COMMENTING = 'VERSION_AVAILABLE_FOR_COMMENTING',
  SHOW_DISCUSSIONS_MARGIN_ICON = 'SHOW_DISCUSSIONS_MARGIN_ICON',
  SHOW_VALIDATION_MARGIN_ICON = 'SHOW_VALIDATION_MARGIN_ICON',
  SHOW_CLAUSE_LINK_MARGIN_ICON = 'SHOW_CLAUSE_LINK_MARGIN_ICON',
  CREATE_TEMPLATE = 'CREATE_TEMPLATE',
  UPDATE_VERSION_TAG = 'UPDATE_VERSION_TAG',
  CAN_TOGGLE_USER = 'CAN_TOGGLE_USER',
  SHOW_PUBLISHED_INFORMATION_IN_OVERVIEW = 'SHOW_PUBLISHED_INFORMATION_IN_OVERVIEW',
  SHOW_IMPORTED_INFORMATION_IN_OVERVIEW = 'SHOW_IMPORTED_INFORMATION_IN_OVERVIEW',
  SHOW_INTERNAL_REFERENCE_REFRESH_IN_OVERVIEW = 'SHOW_INTERNAL_REFERENCE_REFRESH_IN_OVERVIEW',
  ENABLE_NAVIGATION_TOGGLE = 'ENABLE_NAVIGATION_TOGGLE',
  SHOW_VERSION_PUBLICATION_INFORMATION_IN_OVERVIEW = 'SHOW_VERSION_PUBLICATION_INFORMATION_IN_OVERVIEW',
  SHOW_VERSION_PUBLICATION_OPTIONS_IN_OVERVIEW = 'SHOW_VERSION_PUBLICATION_OPTIONS_IN_OVERVIEW',
  SHOW_FOR_PUBLICATION_REDIRECT_FROM_LIVE_OVERVIEW = 'SHOW_FOR_PUBLICATION_REDIRECT_FROM_LIVE_OVERVIEW',
  SHOW_REVIEW_STATUS_TOGGLE_IN_OVERVIEW = 'SHOW_REVIEW_STATUS_TOGGLE_IN_OVERVIEW',
  CAN_VIEW_LIVE_DOCUMENT = 'CAN_VIEW_LIVE_DOCUMENT',
  CREATE_DOCUMENT = 'CREATE_DOCUMENT',
  IS_LIVE_VIEW_MODE = 'IS_LIVE_VIEW_MODE',
  IS_HISTORICAL_VIEW_MODE = 'IS_HISTORICAL_VIEW_MODE',
  IS_EXPORT_VIEW_MODE = 'IS_EXPORT_VIEW_MODE',
  IS_BACKGROUND_VIEW_MODE = 'IS_BACKGROUND_VIEW_MODE',
  IS_COMMENTS_VIEW_MODE = 'IS_COMMENTS_VIEW_MODE',
  IS_COMPARE_VIEW_MODE = 'IS_COMPARE_VIEW_MODE',
  IS_LINK_TABLE_VIEW_MODE = 'IS_LINK_TABLE_VIEW_MODE',
  IS_CHANGELOG_VIEW_MODE = 'IS_CHANGELOG_VIEW_MODE',
  IS_CHANGELOG_MARKUP_VIEW_MODE = 'IS_CHANGELOG_MARKUP_VIEW_MODE',
  IS_CHANGELOG_AUX_VIEW_MODE = 'IS_CHANGELOG_AUX_VIEW_MODE',
  IS_MCHW_DOCUMENT = 'IS_MCHW_DOCUMENT',
  EXPORT_DOCUMENT = 'EXPORT_DOCUMENT',
  EXPORT_MCHW_DOCUMENT = 'EXPORT_MCHW_DOCUMENT',
  CAN_TOGGLE_NAAS = 'CAN_TOGGLE_NAAS',
  CAN_EDIT_WSR = 'CAN_EDIT_WSR',
  SHOW_ALT_TEXT = 'SHOW_ALT_TEXT',
  CAN_REVERT_DOCUMENT = 'CAN_REVERT_DOCUMENT',
  CAN_EDIT_INTERNAL_REFERENCE = 'CAN_EDIT_INTERNAL_REFERENCE',
  CAN_VIEW_PRINT_PREVIEW = 'CAN_VIEW_PRINT_PREVIEW',
}

/**
 * An enumeration of all available ASTNode types. Should be kept in sync with
 * the matching backend enum.
 */
export enum ASTNodeType {
  OPERATION = 'OPERATION',
  CONDITION = 'CONDITION',
}

/**
 * An enumeration of all available operation types. Should be kept in sync with
 * the matching backend enum.
 */
export enum OperationType {
  AND = 'AND',
  OR = 'OR',
  NOT = 'NOT',
}

/**
 * An enumeration of all available permission inputs. Should be kept in sync with
 * the matching backend enum.
 */
export enum PermissionInput {
  DOCUMENT_ROLE = 'DOCUMENT_ROLE',
  GLOBAL_ROLE = 'GLOBAL_ROLE',
  SUITE = 'SUITE',
  VERSION_TAG = 'VERSION_TAG',
  VIEW_MODE = 'VIEW_MODE',
  LOCAL_CONFIG = 'LOCAL_CONFIG',
  WORKFLOW_STATUS = 'WORKFLOW_STATUS',
  USER_DISABLED = 'USER_DISABLED',
  LATEST_VERSION_TAG = 'LATEST_VERSION_TAG',
  SECTION_TYPE = 'SECTION_TYPE',
  ADMINISTRATION = 'ADMINISTRATION',
}

/**
 * An enumeration of all available equality relations. Should be kept in sync with
 * the matching backend enum.
 */
export enum EqualityRelation {
  EQUALS = 'EQUALS',
  NOT_EQUALS = 'NOT_EQUALS',
}
